import React from 'react';

export default function Footer() {
  return (
    <div className='pt-12 pb-8 text-center text-gray-600'>
      <p>
        Built by{' '}
        <a href='https://www.zacharyminner.com' className='font-semibold'>
          Zac Minner
        </a>{' '}
        with <span className='text-xl'>☕️</span> and{' '}
        <a href='https://scryfall.com/' target='_blank' rel='noreferrer' className='font-semibold'>
          Scryfall
        </a>
        .
      </p>
    </div>
  );
}
